:root {
	--breadcrumbs-arrow_color: var(--theme-color_neutral-600);
	--breadcrumbs-text_color: var(--theme-color_black);
	--breadcrumbs-divider_color: var(--theme-color_neutral-400);
}

& .logo {
	margin-right: 10px;
	svg {
		width: 24px;
		height: 24px;
	}
}
& .bar {
	margin: 0 10px;
}

.breadcrumbs {
	display: flex;
	align-items: center;
	align-self: flex-start;
}

& .crumbs-wrapper {
	display: flex;
	align-items: center;

	& svg {
		margin-right: 12px;
		transform: rotate(-90deg);

		& > path {
			fill: var(--breadcrumbs-arrow_color);
		}

		@media screen and (--tablet), screen and (--phone) {
			width: 16px;
			height: 16px;
		}
	}

	& a {
		display: flex;
		align-items: center;
	}

	.name {
		font-size: 1.125rem;
		font-weight: 500;
		line-height: 1.75rem;
		letter-spacing: 0.8px;
		color: var(--breadcrumbs-arrow_color);

		@media screen and (--tablet), screen and (--phone) {
			font-size: 1rem;
			line-height: 1.5rem;
		}
	}

	& .last-name {
		color: var(--breadcrumbs-text_color);
	}

	& > .divider-wrapper {
		margin: 0 12px;

		& .divider {
			width: 1px;
			height: 20px;
			background: var(--breadcrumbs-divider_color);
		}
	}

	&:last-child {
		& > .divider-wrapper {
			display: none;
		}
	}
}
