:root {
	--toast-text_color: var(--theme-color_neutral-800);
	--toast-top_position: 80px;
}

.toast-wrapper {
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
}

.toast {
	display: flex;
	align-items: center;
	position: absolute;
	top: -100%;
	padding: 9px 16px;
	border-radius: 4px;
	width: fit-content;
	box-shadow: 0 0 12px rgb(0 0 0 / 8%);
	background-color: var(--theme-color_white);
	animation: slide-in 0.5s;
	animation-delay: 0.2s;
	animation-fill-mode: forwards;

	& > svg {
		height: 20px;
		width: 20px;
		margin-right: 8px;
	}

	& > p {
		font-size: 0.875rem;
		line-height: 1.375rem;
		color: var(--toast-text_color);
	}
}

@keyframes slide-in {
	from {
		top: -100%;
	}

	to {
		top: var(--toast-top_position);
	}
}

.icon {
	margin-right: 8px;
}

.success-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border-radius: 16px;
	background-color: var(--functional-success);

	svg {
		width: 75%;
	}

	path {
		fill: white;
	}
}

.warning-icon {
	& > svg {
		width: 20px;
		height: 20px;
	}
}
