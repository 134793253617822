:root {
	/* Primay - Sprout */
	--theme-color_primary-900: #00424a;
	--theme-color_primary-800: #015863;
	--theme-color_primary-700: #006e7b;
	--theme-color_primary-600: #0092a4;
	--theme-color_primary-500: #00afc5;
	--theme-color_primary-400: #15d5ee;
	--theme-color_primary-300: #aac6ca;
	--theme-color_primary-200: #94f3ff;
	--theme-color_primary-100: #e7fcff;
	--theme-color_primary: var(--theme-color_primary-500);

	/* Neutral - Gray Scale */
	--theme-color_neutral-900: #3d3d3d;
	--theme-color_neutral-800: #4a4a4a;
	--theme-color_neutral-700: #6b6b6b;
	--theme-color_neutral-600: #7c7c7c;
	--theme-color_neutral-550: #939393;
	--theme-color_neutral-500: #a7a7a7;
	--theme-color_neutral-400: #d3d3d3;
	--theme-color_neutral-300: #e9e9e9;
	--theme-color_neutral-200: #f1f1f1;
	--theme-color_neutral-100: #f9f9f9;
	--theme-color_neutral: var(--theme-color_neutral-500);

	/* Primay - Pink */
	--theme-color_pink-1000: #D9516C;
	--theme-color_pink-900: #60181a;
	--theme-color_pink-800: #9b3134;
	--theme-color_pink-700: #bb4044;
	--theme-color_pink-600: #d55256;
	--theme-color_pink-500: #f2656a;
	--theme-color_pink-400: #ff979b;
	--theme-color_pink-300: #e0c5c5;
	--theme-color_pink-200: #ffe5e6;
	--theme-color_pink-100: #fff0f0;

	/* Functional */
	--functional-success: #6fd993;

	/* Black */
	--theme-color_black: #252525;

	/* White */
	--theme-color_white: #fff;
	--dropdown-index: 1;

	/* Warning */
	--warning: #fd4f4f;

	/* Caution */
	--caution: #ffc507;
}
