@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loading-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 80vh;

	& > .loading {
		border-radius: 50%;
		width: 62px;
		height: 62px;
		margin-bottom: 12px;
		border: 0.5rem solid rgba(108 108 108 / 30%);
		border-top-color: var(--theme-color_primary-500);
		animation: spin 1.2s infinite linear;
	}
}
