:root {
	--header-border_color: var(--theme-color_neutral-300);
	--mobile_header_menu-height: 56px;
	--mobile_header_menu_trigger-color: var(--theme-color_neutral-800);
	--mobile_header_menu_trigger-color-close: var(--theme-color_black);
}

.header {
	display: flex;
	justify-content: space-between;
	padding: 16px 32px 15px;
	border-bottom: 1px solid var(--header-border_color);

	& > .right-wrapper {
		display: flex;

		& .button {
			padding: 5px 30px;
			margin-right: 20px;
		}

		& .button-red {
			padding: 5px 30px;
			margin-right: 20px;
			background-color: #DB4F6A;
			border: 1px solid #DB4F6A;

			&:hover {
				background-color: #cd2546;
			}
		}
	}
	.title {
		margin: 0 10px;
	}
}

.panel {
	position: absolute;
	width: 100%;
	margin-top: 8px;
	padding: 20px 24px;
	border-radius: 8px;
	background: var(--theme-color_white);
	border: 1px solid var(--theme-color_neutral-400);

	&:hover {
		cursor: pointer;
		background: var(--theme-color_neutral-100);
	}
}

.mobile-wrapper {
	background: #f1f4fa;

	& > .breadcrumbs {
		padding: 16px 16px 0;
	}
}

.mobile-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--header-border_color);
	padding: 12px 16px;
	min-height: var(--mobile_header_menu-height);
	background: var(--theme-color_white);

	&.close {
		border-color: transparent;
	}

	& > .content {
		display: flex;
		align-items: center;

		& > .menu-trigger {
			display: flex;

			& > svg {
				width: 24px;
				height: 24px;

				path {
					fill: var(--mobile_header_menu_trigger-color);
				}
			}

			&.close > svg > path {
				fill: var(--mobile_header_menu_trigger-color-close);
			}
		}

		& svg {
			width: 24px;
			height: 24px;
		}

		& > svg:nth-child(2) {
			width: 24px;
			height: 24px;
			margin-left: 12px;
		}

		& > .title {
			margin: 0 8px 0 4px;
			font-size: 1rem;
			font-weight: 500;
			line-height: 1.5rem;
		}
	}
}

.flex {
	display: flex;
	align-items: center;
}
