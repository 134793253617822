:root {
	--header-border_color: var(--theme-color_neutral-300);
	--mobile_header_menu-height: 56px;
	--header_menu_item-text_color: var(--theme-color_neutral-600);
	--header_menu_item-text_color-active: var(--theme-color_black);
	--header_menu_item-background_color-hover: var(--theme-color_neutral-100);
	--header_menu_item-background_color-active: var(--theme-color_pink-100);
}

.side-menu {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: fixed;
	top: var(--mobile_header_menu-height);
	bottom: 0;
	left: 0;
	right: 0;
	padding: 24px;
	background-color: var(--theme-color_white);
	z-index: 1;
	overflow-y: auto;

	@media screen and (--desktop) {
		position: static;
		padding: 16px;
		height: 100%;
	}
}

.menu-item {
	display: flex;
	flex-direction: column;

	&:not(:last-child) {
		margin-bottom: 16px;
	}

	& > .menu-item-link {
		display: flex;
		align-items: center;
		border-radius: 8px;
		padding: 12px 20px;
		width: 100%;
		font-weight: 500;
		line-height: 1.5rem;
		color: var(--header_menu_item-text_color);

		& > .icon {
			display: inline-flex;
			width: 20px;
			height: 20px;
			margin-right: 8px;

			&.trigger-icon {
				margin-left: auto;
				margin-right: 0;

				& > svg {
					transform: rotate(360deg);
					transition: all 0.3s ease-in-out;
				}
			}

			& > svg {
				width: 100%;
				height: 100%;

				& path {
					fill: var(--header_menu_item-text_color);
				}
			}
		}

		&:hover {
			color: var(--header_menu_item-text_color-active);
			background-color: var(--header_menu_item-background_color-hover);

			& > .icon > svg path {
				fill: var(--header_menu_item-text_color-active);
			}
		}

		&.active {
			color: var(--header_menu_item-text_color-active);
			background-color: var(--header_menu_item-background_color-active);

			& > .icon > svg path {
				fill: var(--header_menu_item-text_color-active);
			}
		}

		&.open {
			color: var(--header_menu_item-text_color-active);

			& > .icon > svg path {
				fill: var(--header_menu_item-text_color-active);
			}

			& > .trigger-icon > svg {
				transform: rotate(180deg);
			}
		}
	}

	& .sub-menus {
		display: flex;
		flex-direction: column;
		min-height: 0;
		transition: all 0.3s ease-in-out;
	}

	& > .expandable {
		display: grid;
		grid-template-rows: 0fr;
		transition: all 0.3s ease-in-out;
		overflow: hidden;

		&.open {
			grid-template-rows: 1fr;

			& > .sub-menus {
				margin-top: 4px;

				& > .menu-item {
					& > .menu-item-link {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						padding-left: 48px;
						padding-right: 10px;
					}
				}
			}
		}
	}
}

.mt10 {
	margin-top: 10px;
}

.button-red {
	background-color: #DB4F6A;
	border: 1px solid #DB4F6A;
	margin-top: 10px;

	&:hover {
		background-color: #cd2546;
		border: 1px solid #DB4F6A;
	}
}
