:root {
	--button-font_color-primary: var(--theme-color_white);
	--button-font_color-primary-hover: var(--theme-color_white);
	--button-font_color-primary-pressed: var(--theme-color_white);
	--button-font_color-primary-disabled: var(--theme-color_white);
	--button-font_color-secondary: var(--theme-color_primary-500);
	--button-font_color-secondary-hover: var(--theme-color_primary-500);
	--button-font_color-secondary-pressed: var(--theme-color_primary-700);
	--button-font_color-secondary-disabled: var(--theme-color_neutral-600);
	--button-background_color-primary: var(--theme-color_primary-500);
	--button-background_color-primary-hover: var(--theme-color_primary-600);
	--button-background_color-primary-pressed: var(--theme-color_primary-700);
	--button-background_color-primary-disabled: var(--theme-color_primary-300);
	--button-background_color-secondary: var(--theme-color_white);
	--button-background_color-secondary-hover: var(--theme-color_primary-100);
	--button-background_color-secondary-pressed: var(--theme-color_primary-100);
	--button-background_color-secondary-disabled: var(--theme-color_neutral-300);
	--button-border_color-secondary: var(--theme-color_primary-500);
	--button-border_color-secondary-hover: var(--theme-color_primary-500);
	--button-border_color-secondary-pressed: var(--theme-color_primary-700);
	--button-border_color-secondary-disabled: var(--theme-color_neutral-500);
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px 12px;
	min-width: 110px;
	font-size: 0.875rem;
	line-height: 1.375rem;
	border-radius: 6px;
	font-weight: 500;
	cursor: pointer;

	&[disabled] {
		cursor: not-allowed;
	}

	& > svg {
		margin-left: 8px;
		width: 16px;
		height: auto;
	}
}

.button-red {
	background-color: #DB4F6A;
}

.button-size-m {
	padding: 8px 12px;
	min-width: 140px;
}

.button-size-l {
	padding: 12px 16px;
	font-size: 1rem;
	line-height: 1.5rem;
	min-width: 160px;

	& > svg {
		width: 20px;
	}
}

.button-size-xl {
	padding: 15px 24px;
}

.button-primary {
	color: var(--button-font_color-primary);
	border: 1px solid var(--button-background_color-primary);
	background-color: var(--button-background_color-primary);

	& > svg > path {
		fill: var(--button-font_color-primary);
	}

	&:hover {
		color: var(--button-font_color-primary-hover);
		border-color: var(--button-background_color-primary-hover);
		background-color: var(--button-background_color-primary-hover);

		& > svg > path {
			fill: var(--button-font_color-primary-hover);
		}
	}

	&:active {
		color: var(--button-font_color-primary-pressed);
		border-color: var(--button-background_color-primary-pressed);
		background-color: var(--button-background_color-primary-pressed);

		& > svg > path {
			fill: var(--button-font_color-primary-pressed);
		}
	}

	&[disabled] {
		color: var(--button-font_color-primary-disabled);
		border-color: var(--button-background_color-primary-disabled);
		background-color: var(--button-background_color-primary-disabled);

		& > svg > path {
			fill: var(--button-font_color-primary-disabled);
		}
	}
}

.button-secondary {
	color: var(--button-font_color-secondary);
	border: 1px solid var(--button-border_color-secondary);
	background-color: var(--theme-color_white);

	& > svg > path {
		fill: var(--button-font_color-secondary);
	}

	&:hover {
		color: var(--button-font_color-secondary-hover);
		border-color: var(--button-border_color-secondary-hover);
		background-color: var(--button-background_color-secondary-hover);

		& > svg > path {
			fill: var(--button-font_color-secondary-hover);
		}
	}

	&:active {
		color: var(--button-font_color-secondary-pressed);
		border-color: var(--button-border_color-secondary-pressed);
		background-color: var(--button-background_color-secondary-pressed);

		& > svg > path {
			fill: var(--button-font_color-secondary-pressed);
		}
	}

	&[disabled] {
		color: var(--button-font_color-secondary-disabled);
		border-color: var(--button-border_color-secondary-disabled);
		background-color: var(--button-background_color-secondary-disabled);

		& > svg > path {
			fill: var(--button-font_color-secondary-disabled);
		}
	}
}

.button-danger {
	color: var(--theme-color_white);
	background-color: var(--warning);
}

.button-tertiary {
	border: 1px solid var(--theme-color_neutral-500);
}
