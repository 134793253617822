/*
 * This is for global css usage.
 */
@import '../config/palette.css';

* {
	box-sizing: border-box;
	letter-spacing: 0.8px;
	font-family: 'Noto Sans TC', sans-serif;
}

body {
	overscroll-behavior: none;
	color: var(--theme-color_black);
}

a,
a:link,
a:visited,
a:hover,
a:active,
a:focus {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
}

button {
	padding: 0;
	outline: none;
	border: 0;
	background-color: transparent;
	cursor: pointer;
}

input {
	outline: none;
	border: 0;
}

/* stylelint-disable-next-line selector-no-vendor-prefix */
input:focus::-webkit-input-placeholder {
	opacity: 0;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
}

textarea {
	border: none;
	resize: none;
}
