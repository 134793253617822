:root {
	--avatar_info-text_color: var(--theme-color_neutral-900);
	--avatar_info-border_color: var(--theme-color_neutral-300);
	--avatar_info-border_color-active: var(--theme-color_neutral-400);
	--avatar_info-background_color-hover: var(--theme-color_neutral-100);
	--avatar_info-background_color-active: var(--theme-color_neutral-200);
	--avatar_info_icon-color: var(--theme-color_neutral-700);
}

.avatar-info {
	display: flex;
	align-items: center;
	border: 1px solid var(--avatar_info-border_color);
	border-radius: 100px;
	padding: 3px 7px 3px 3px;
	cursor: pointer;

	&:hover {
		background: var(--avatar_info-background_color-hover);
		border-color: var(--avatar_info-border_color-active);
	}

	&.open {
		background-color: var(--avatar_info-background_color-active);
		border-color: var(--avatar_info-border_color-active);
	}

	& > .name {
		margin: 0 4px;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.375rem;
		color: var(--avatar_info-text_color);
	}

	& > .icon {
		display: flex;
		width: 16px;

		& > svg {
			width: 100%;
			transition: all 0.3s ease-in-out;
			transform: rotate(180deg);

			& > path {
				fill: var(--avatar_info_icon-color);
			}
		}

		&.open {
			& > svg {
				transition: all 0.3s ease-in-out;
				transform: rotate(0deg);
			}
		}
	}

	@media screen and (--desktop) {
		padding: 3px 11px 3px 3px;

		& > .name {
			margin: 0 8px;
		}
	}

	&.disabled {
		pointer-events: none;
	}
}

.dropdown-panel {
	padding: 0;
}

.logout-btn {
	padding: 20px 24px;
	width: 100%;
	line-height: 1.375rem;
	text-align: left;

	&:hover {
		cursor: pointer;
		background: var(--theme-color_neutral-100);
	}
}
