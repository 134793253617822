:root {
	--side_bar-border_color: var(--theme-color_neutral-300);
}

.side-bar {
	display: flex;
	flex-direction: column;
	flex: 0 0 240px;
	height: 100vh;
	border-right: 1px solid var(--side_bar-border_color);
	overflow-y: hidden;

	& > .logo {
		display: flex;
		align-items: center;
		padding: 18px 20px;

		& > svg {
			width: 24px;
			height: 24px;
		}

		& .title {
			margin: 0 8px;
			font-size: 1.125rem;
			font-weight: 500;
			line-height: 1.75rem;
		}
	}
}
