.alert {
	display: flex;
	gap: 0 12px;
	margin: auto;
	padding: 16px;
	border-radius: 4px;
	opacity: 0;
	box-shadow: 0 0 12px 0 rgb(0 0 0 / 12%);
	animation: fade-in 0.5s;
	animation-delay: 0.2s;
	animation-fill-mode: forwards;
	background: var(--theme-color_white);
	position: fixed;
	top: 32px;
	left: 0;
	right: 0;
}

.success {
	max-width: 400px;
	border-left: 4px solid var(--functional-success);
}

.warning {
	max-width: 450px;
	border-left: 4px solid var(--warning);
}

.caution {
	max-width: 400px;
	border-left: 4px solid var(--caution);
}

@keyframes fade-in {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.success-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
	border-radius: 16px;
	background-color: var(--functional-success);

	svg {
		width: 75%;
	}

	path {
		fill: white;
	}
}

.warning-icon {
	& > svg {
		width: 20px;
		height: 20px;
	}
}

.main-area {
	flex: 1;
}

.title {
	margin-bottom: 8px;
	font-size: 16px;
	font-weight: 500;
}

.content {
	margin-bottom: 8px;
	font-size: 14px;
	font-weight: 400;

	/* For link inside of content */
	& > a {
		color: var(--theme-color_primary-600);
		text-decoration: underline;
	}
}

.button-wrapper {
	text-align: right;
	font-size: 14px;
	font-weight: bold;
}

.close-button {
	width: 24px;
	height: 24px;
	cursor: pointer;
	box-sizing: content-box;
	position: relative;
	top: -4px;

	&::before,
	&::after {
		content: '';
		width: 66%;
		height: 2px;
		margin: auto;
		border-radius: 16px;
		background: var(--theme-color_neutral-700);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}
}
