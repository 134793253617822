:root {
	--app_content-background_color: #f1f4fa;
}

.app-wrapper {
	display: flex;
	justify-content: center;

	& > .app-content {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		height: 100vh;
		overflow-y: auto;

		& > .wrapper {
			display: flex;
			flex-direction: column;
			padding: 32px;
			height: 100%;
			background-color: var(--app_content-background_color);
			overflow-y: auto;

			@media screen and (--tablet), screen and (--phone) {
				padding: 0;
			}

			.breadcrumbs {
				margin: 16px 16px 12px;
			}
		}
	}
}
