:root {
	--dropdown_panel-background_color: var(--theme-color_white);
	--dropdown_panel-border_color: var(--theme-color_neutral-400);
}

.dropdown {
	position: relative;
}

.dropdown-trigger {
	width: 100%;
}

.dropdown-panel {
	position: absolute;
	width: 100%;
	margin-top: 8px;
	padding: 12px;
	border-radius: 8px;
	background: var(--dropdown_panel-background_color);
	border: 1px solid var(--dropdown_panel-border_color);
	overflow: hidden;
	z-index: var(--dropdown-index);
}
